<template>
  <!--  KTB Mobile  -->
  <div>
    <b-overlay :show="isTogging">
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            KTB Mobile App
            <AutoBankingStatus :status="!!ktbAccount" />
            <br>
            <small class="text-muted">(รองรับฝากและถอน)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="ktbAccount"
              id="ktbAccountStatus"
              v-model="ktbAccount.isEnabled"
              :disabled="isTogging"
              name="ktbAccountStatus"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!ktbAccount"
              @click="$bvModal.show('ktb-mobile-app-modal')"
          />
          <KtbMobileAppModal :agent-bank-account-id="bankAccountForm.id" :ktb-account="ktbAccount" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoKtbForm',
  components: {
    KtbMobileAppModal: () => import('./mobile-app-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        ktbAccount: null
      })
    }
  },
  data() {
    return {
      ktbAccount: null,
      isTogging: false,
    }
  },
  watch: {
    'ktbAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('ktb-mobile')
      }
    },
  },
  created() {
    this.ktbAccount = this.bankAccountForm.ktbAccount
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>